import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "mobileMenu", "mobileMenuButton", "mobileMenuBarsIcon", "mobileMenuXIcon" ]
  static values = { open: Boolean }

  toggle() {
    this.openValue = !this.openValue

    this.mobileMenuBarsIconTarget.classList.toggle('hidden');
    this.mobileMenuXIconTarget.classList.toggle('hidden');
  }
}
